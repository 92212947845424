<template>
    <b-card title="New Order">
        <b-form>
            <b-row>
                <b-col md="8" class="border">
                    <b-row class="mt-2">
                        <b-col md="12">
                            <b-form>
                                <b-input-group class="mt-2 mr-2">
                                    <b-input-group-prepend>
                                        <div v-if="Selected == 'Search Customer'">
                                            <vSelect :options="searchCritriaOption" label="name" v-model="searchCritria"
                                                placeholder="Search ">
                                            </vSelect>
                                        </div>
                                    </b-input-group-prepend>

                                    <v-select v-if="Selected == 'Search Customer'" class="w-75" :filterable="false"
                                        :placeholder="Selected" :options="customersList" @search="onInputChange"
                                        v-model="searchBoxValue" @input="onSelected">
                                        <template #option="{ name, mobileNo, id }">
                                            <h4>{{ name }}{{ [id] }}</h4>
                                            <span>{{ mobileNo }} </span>
                                        </template>
                                    </v-select>
                                </b-input-group>
                            </b-form>
                        </b-col>
                    </b-row>
                    <hr>

                    <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">

                        <b-row v-for="(item, index) in items" :id="item" :key="item" ref="row">
                            <b-col class="border mt-1" :class="{ 'odd': index % 2 === 0, 'even': index % 2 !== 0 }">
                                <b-row>
                                    <!-- Item Name and Image -->
                                    <b-col md="2" class="mt-1 text-center">
                                        <b-form-group class="font-color">
                                            <h6 class="font-color" v-once> {{ category.name }} - {{
                                                item.itemName
                                            }}
                                            </h6>
                                            <img v-once :src=convertImage(servicesPriceList[0].itemPic) height="70px"
                                                alt="image" />
                                        </b-form-group>
                                    </b-col>

                                    <!-- Attributes Name -->
                                    <b-col md="5">
                                        <b-form-group class="mt-1 font-color padding_attribute">
                                            <li class="inline attribute-font-size" v-for="brands, index0 in item.brands"
                                                :key="brands">
                                                <span>{{ brands.name }}</span>
                                                <span v-if="!(item.brands.length == index0 + 1)"> , </span>
                                            </li> -
                                            <li class="inline attribute-font-size"
                                                v-for="defects, index1 in item.garmentRemarks" :key="defects">
                                                <span>{{ defects.name }}</span><span
                                                    v-if="!(item.garmentRemarks.length == index1 + 1)"> , </span>
                                            </li> -
                                            <li class="inline attribute-font-size" v-for="colors, index2 in item.colors"
                                                :key="colors">
                                                <span>{{ colors.name }}</span> <span
                                                    v-if="!(item.colors.length == (index2 + 1))"> , </span>
                                            </li>
                                            <b-button v-b-toggle.sidebar-right variant="flat-primary"
                                                class="btn-icon inline attribute-font-size" @click='addAttribute(index)'>
                                                <feather-icon icon="PlusIcon" />Attributes
                                            </b-button>
                                        </b-form-group>
                                        <b-row class="mb-1 padding_attribute">
                                            <b-col md="12">
                                                <b-col>
                                                    <b-row>
                                                        <h5 class="mr-1 font-color attribute-font-size"> Quantity</h5>
                                                        <b-form-spinbutton v-once v-model="item.quantity" min="1"
                                                            class="mr-1 spin-button" @change="quantityAdd(index)" />
                                                        <h5 class="mr-1 font-color attribute-font-size"> Pieces - </h5>
                                                        <h5 class="mr-1 font-color attribute-font-size">{{
                                                            item.pieceCount
                                                        }}
                                                        </h5>
                                                    </b-row>
                                                    <b-row>
                                                        <h5 class="mr-1 font-color attribute-font-size"> Amount - </h5>
                                                        <h5 class="mr-1 font-color attribute-font-size">{{
                                                            (item.quantity *
                                                                item.orderServicePrice) + item.totalTupUpServiceAmount
                                                        }}</h5>
                                                        <!-- <h5 v-if="topupAttributes.length > 0"
                                                                class="mr-1 font-color attribute-font-size">
                                                                {{ (item.quantity *
            item.orderServicePrice) + totalAttributeAmount
    }}</h5> -->
                                                    </b-row>
                                                </b-col>
                                            </b-col>
                                        </b-row>
                                    </b-col>

                                    <!-- Service List -->
                                    <b-col md="5">
                                        <b-row style="float: right;">
                                            <b-form-group class="mt-1 font-color text-center">
                                                <!-- Service List -->
                                                <b-row>
                                                    <b-col col="11">
                                                        <span class="font-color service-font-size" v-once>
                                                            {{ servicesSelected.name }}
                                                        </span>
                                                    </b-col>
                                                    <b-col col="1">
                                                        <b-form-input class="input-box" v-once
                                                            v-model="item.orderServicePrice" @input="onInputChangeAmount">
                                                        </b-form-input>
                                                    </b-col>
                                                </b-row>

                                                <!-- TopUp service List -->
                                                <b-row v-for="topup in item.topUpServices" :key="topup.name">
                                                    <b-col col="11">
                                                        <span class="font-color service-font-size">
                                                            {{ topup.name }}
                                                        </span>
                                                    </b-col>
                                                    <b-col col="1">
                                                        <b-form-input class="input-box" disabled
                                                            :value=topup.topUpServicePrice>
                                                        </b-form-input>
                                                    </b-col>
                                                </b-row>

                                            </b-form-group>
                                            <b-form-group class="mt-1 edit-button">
                                                <b-button class="mr-1 btn-icon" variant="primary" style="padding: 0.3rem;"
                                                    @click="removeItem(index)">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </b-form-group>
                                        </b-row>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>
                    </b-form>
                    <hr>
                    <b-row class="navbar-sticky">
                        <b-col md="3">
                            <b-form-group label="Item Category">
                                <vSelect :options="categoryOption" v-model="category" label="name"
                                    @input="selectItemCategory(category.name)" placeholder="---Select Category---">
                                </vSelect>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Item Name">
                                <vSelect :options="itemNameOption" v-model="itemName" label="name"
                                    @input="selectItemName(itemName.id)" placeholder="---Select Item---">
                                </vSelect>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group label="Services">
                                <v-select placeholder="Select Services" v-model="servicesSelected" label="name"
                                    :options="servicesOption">
                                    <!-- <template #option="{ service, servicePrice }">
                                            <span class="mr-1">{{ service }}</span>
                                            <span>[ {{ servicePrice }} ]</span>
                                        </template> -->
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-2 mr-2" variant="primary"
                                @click="repeateAgain">Add
                            </b-button>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="8">
                            <b-form-textarea rows="2" placeholder="Enter Order Remark"
                                v-model="orderRemark"></b-form-textarea>
                        </b-col>

                        <!-- <b-col md="4">
                                <b-row>
                                    <b-col md="6">
                                        <b-button block>Send SMS</b-button>
                                    </b-col>
                                    <b-col md="6">
                                        <b-button block>Send Email</b-button>
                                    </b-col>
                                </b-row>

                                <b-row class="mt-1">
                                    <b-col md="6">
                                        <b-button block @click="printTag">
                                            {{ isActivePrintTag? 'Print Tag ✔': 'Print Tag' }}
                                        </b-button>
                                    </b-col>
                                    <b-col md="6">
                                        <b-button block>Print Receipt</b-button>
                                    </b-col>
                                </b-row>
                            </b-col> -->
                        <b-col md="4">
                            <b-form-checkbox @input="setUrgent1" v-model="urgent1" switch>{{ urgentName1 + ' (' +
                                urgentRate1 + '%)' }}</b-form-checkbox>

                            <b-form-checkbox @input="setUrgent2" v-model="urgent2" switch>{{ urgentName2 + ' (' +
                                urgentRate2 + '%)' }}</b-form-checkbox>

                            <b-button v-if="isHomeDeliveryAllowed" block v-model="enableHomeDelivery">Home
                                Delivery</b-button>
                        </b-col>
                    </b-row>
                    <hr>
                </b-col>
                <b-col md="4" class="border">
                    <b-row class="mt-1">
                        <b-col>
                            <h4>{{ customerIdName }}</h4>
                            <h3><b>{{ customerName }}</b></h3>
                            <h5>{{ customerMobileNo }}</h5>
                            <h6>{{ customerAddress }}</h6>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="12">
                            <b-form-group label="Due Date">
                                <flat-pickr v-model="dueDate" class="form-control" name="date"
                                    placeholder="Please Enter Due Date"
                                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss' }" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr>

                    <b-row>
                        <b-col md="9">
                            <label>Total Service Default Amount</label>
                        </b-col>
                        <b-col md="3">
                            <h5>{{ totalDefultAmount }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="9">
                            <label>Total Amount Less From Default Amount</label>
                        </b-col>
                        <b-col md="3">
                            <h5>{{ amountLessFromDefaultAmount }}</h5>
                        </b-col>
                    </b-row>
                    <!-- <b-row>
                            <b-col md="10">
                                <h5>Total Topup Service Default Amount</h5>
                            </b-col>
                            <b-col md="2">
                                <label>20000</label>
                            </b-col>
                        </b-row> -->
                    <hr>
                    <b-row>
                        <b-col md="4" class="text-center">
                            <label>Quantity</label>
                            <h5>{{ totalQuantity }}</h5>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <label>Pieces</label>
                            <h5>{{ totalPieces }}</h5>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <label>Amount</label>
                            <h5>{{ totalAmount }}</h5>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row v-if="discountAllow">
                        <b-col md="12">
                            <h5>Discount (Flat / Percentage)</h5>
                            <b-form-checkbox v-model="isPercentage" disabled> Is Percentage</b-form-checkbox>
                            <b-form-input placeholder="Enter Discount" @input="discountCalculation"
                                v-model="discountAmountPercentage"></b-form-input>

                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="6">
                            <label>Total Gross Amount</label>
                        </b-col>
                        <b-col md="6">
                            <h5>{{ totalGrossAmount }}</h5>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row v-if="istaxRateEnable">
                        <b-col md="4" class="text-center">
                            <label>{{ subTaxNameAndRate1 }}</label>
                            <h5>{{ totalSubTaxAmount1 }}</h5>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <label>{{ subTaxNameAndRate2 }}</label>
                            <h5>{{ totalSubTaxAmount2 }}</h5>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <label>{{ taxNameAndRate }}</label>
                            <h5>{{ totalTaxRate }}</h5>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="6">
                            <label>Total Net Amount</label>
                        </b-col>
                        <b-col md="6">
                            <h5>{{ parseFloat(totalNetAmount).toFixed(2) }}</h5>
                        </b-col>
                    </b-row>
                    <hr>
                    <!-- <b-row>
                            <b-col md="6">
                                <b-form-group label="Advance">
                                    <vSelect v-model="advance" :options="advanceOption"></vSelect>
                                </b-form-group>

                            </b-col>
                            <b-col md="6">
                                <b-form-group label="Amount">
                                    <b-form-input placeholder="Enter Advance Amount"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row> -->
                    <b-row>
                        <b-col md="6">
                            <label>Current Due</label>
                        </b-col>
                        <b-col md="6">
                            <h5>{{ parseFloat(totalDueAmount).toFixed(2) }}</h5>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col md="12">
                            <b-form-group label="Garment Inspection by">
                                <vSelect :options="garmentInspectorByOptions" label="name" v-model="garmentInspectorBy">
                                </vSelect>
                            </b-form-group>
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col md="12" class="text-center">
                            <b-button :disabled="saveOrderDisable" variant="primary" @click="saveOrder">
                                <feather-icon icon="SaveIcon" class="mr-50" />
                                Save Order
                            </b-button>
                        </b-col>
                    </b-row>
                    <hr>
                </b-col>
            </b-row>

            <!-- Attributes from right side Pop Up -->
            <attributes :getTopUpAttributes="getTopUpAttributes" :getBrands="getBrands" :getDefects="getDefects"
                :getColors="getColors" :rowIndex="rowIndex" :brandsAttributes="brandsAttributes"
                :topupAttributes="topupAttributes" :defectsAttributes="defectsAttributes"
                :colorsAttributes="colorsAttributes" />
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BImg, BFormTimepicker, BFormDatepicker, BInputGroup, BInputGroupPrepend,
    BFormTextarea, VBToggle, BSidebar, BFormSpinbutton, BFormTags, BModal, BFormSelect, BFormTag, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Attributes from './Attributes.vue'
import api from '@/store/api'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import util from '@/store/utils'
import moment from 'moment'
export default {
    components: {
        BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BImg, BFormTextarea, BFormTimepicker, BFormDatepicker, BInputGroup, BInputGroupPrepend,
        vSelect, flatPickr, VBToggle, BSidebar, Attributes, BFormSpinbutton, BModal, BFormTags, BFormSelect, BFormTag, BFormCheckbox
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    mixins: [heightTransition],
    data() {
        return {
            searchCritriaOption: [
                { 'filterType': 'all', 'name': 'All' },
                { 'filterType': 'name', 'name': 'Name' },
                { 'filterType': 'address', 'name': 'Address' },
                { 'filterType': 'mobileNo', 'name': 'Mobile No' },
                { 'filterType': 'emailId', 'name': 'Email Id' },
                { 'filterType': 'membershipId', 'name': 'Membership Id' }
            ],
            searchCritria: { 'filterType': 'mobileNo', 'name': 'Mobile No' },
            Selected: 'Search Customer',
            inputSearch: '',
            name: '',
            mainProps: {
                blank: true,
                width: 60,
                height: 80,
            },
            rowIndex: -1,
            items: [],
            nextTodoId: 2,
            categoryOption: [],
            category: '',
            itemNameOption: [],
            itemName: '',
            servicesOption: [],
            servicesSelected: '',
            servicesPriceList: [],

            orderRemark: '',

            dueDate: '',
            dueDays: 0,
            advanceOption: ['Cash', 'Cheque'],

            discountAllow: false,
            isPercentage: false,
            discountAmountPercentage: 0,
            totalGrossAmount: 0,
            totalDueAmount: 0,

            value: [],

            advance: 'Cash',
            quantity: 0,
            totalQuantity: 0,
            totalPieces: 0,
            totalAmount: 0,
            isActivePrintTag: '',
            topupAttributes: [],
            brandsAttributes: [],
            defectsAttributes: [],
            colorsAttributes: [],

            rowAmount: 0,
            totalAttributeAmount: 0,
            totalDefultAmount: 0,
            amountLessFromDefaultAmount: 0,
            totalNetAmount: 0,
            customerIdName: '',

            istaxRateEnable: false,
            taxNameAndRate: '',
            totalTaxAmount: 0,
            totalTaxRate: 0,
            subTaxNameAndRate1: '',
            totalSubTaxAmount1: 0,
            subTaxNameAndRate2: '',
            totalSubTaxAmount2: 0,



            brandsOptions: [],
            defectOptions: [],
            colorOptions: [],
            topUpServieOption: [],
            customersList: [],
            searchBoxValue: '',
            customerId: 0,
            customerName: '',
            customerMobileNo: '',
            garmentInspectorBy: {},
            garmentInspectorByOptions: [],
            storeId: 0,
            employeeId: 0,
            customerAddress: '',
            customerDiscountRatePercentage: 0,

            defaultConfiguration: JSON.parse(localStorage.getItem('defaultConfiguration')),

            isHomeDeliveryAllowed: false,
            enableHomeDelivery: false,

            isUrgentOrder1: false,
            urgent1: false,
            urgentName1: '',
            urgentRate1: 0,
            urgentDatOffSet1: 0,

            isUrgentOrder2: false,
            urgent2: false,
            urgentName2: '',
            urgentRate2: 0,
            urgentDatOffSet2: 0,

            maxDiscountRestricationPersentage: this.$store.state.maxDiscountRestricationPersentage,
            maxDiscountRestricationFlat: this.$store.state.maxDiscountRestricationFlat,
            taxRates: {},

            saveOrderDisable: false
        }
    }, mounted() {
        this.initTrHeight()

    },
    created() {
        window.addEventListener('resize', this.initTrHeight);
        let self = this;
        if (self.$route.params.mobileNo != undefined) {
            self.searchBoxValue = self.$route.params.mobileNo;
            self.onInputChange(self.searchBoxValue);
        }

        self.setDueDate();



        self.getItemCategory();

        self.getGarmentInspectedBy();
        // self.configration();

        self.getBrandsList();
        self.getGarmentRemarks();
        self.getColorsList();
        self.getTopupServicesList();


    },
    destroyed() {
        window.removeEventListener('resize', this.initTrHeight)
    },
    computed: {
        availableOptions() {
            return this.servicesOption.filter(opt => this.value.indexOf(opt) === -1)
        },

    },
    methods: {
        repeateAgain() {
            if (!(this.servicesSelected.name == null)) {
                if (this.customerId > 0) {
                    this.getServicePriceList();
                } else {
                    this.$swal({
                        title: '',
                        text: 'Please Select Customer.',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                }
            } else {
                this.$swal({
                    title: '',
                    text: 'Please Select Services.',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },

        removeItem(index) {
            this.totalPieces = this.totalPieces - this.items[index].pieceCount;
            this.items.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)

            this.quantityAdd();
        },
        initTrHeight() {
            this.trSetHeight(null)
            this.$nextTick(() => {
                this.trSetHeight(this.$refs.form.scrollHeight)
            })
        },

        printTag() {
            if (!this.isActivePrintTag) {
                this.isActivePrintTag = true;
            } else {
                this.isActivePrintTag = false;
            }
        },

        getTopUpAttributes(topupAttributes, rowIndex) {
            this.getTopupServicesList(topupAttributes, rowIndex);
            // this.topupAttributes = topupAttributes;
            // this.items[rowIndex].topUpServices = [];
            // this.items[rowIndex].totalTupUpServiceAmount = 0
            // for (let i = 0; i < topupAttributes.length; i++) {
            //     const object = this.topUpServieOption.find(obj => obj.name == topupAttributes[i].name);
            //     this.items[rowIndex].topUpServices.push({ "id": object.id, "name": object.name, "topUpServicePrice": object.topUpServicePrice, 'createdBy': object.createdBy, 'createdDate': object.createdDate, 'lastModifiedBy': object.lastModifiedBy, 'lastModifiedDate': object.lastModifiedDate });
            //     this.items[rowIndex].totalTupUpServiceAmount += object.topUpServicePrice;
            // }

            this.quantityAdd();
        },

        getBrands(brandsAttributes, rowIndex) {
            this.getBrandsList(brandsAttributes, rowIndex);
            // alert('aaaaaa')
            // this.brandsAttributes = brandsAttributes
            // this.items[rowIndex].brands = [];
            // for (let i = 0; i < brandsAttributes.length; i++) {
            //     const object = this.brandsOptions.find(obj => obj.name == brandsAttributes[i]);
            //     this.items[rowIndex].brands.push(object);
            // }
        },
        getDefects(defectsAttributes, rowIndex) {
            this.getGarmentRemarks(defectsAttributes, rowIndex);
            // this.defectsAttributes = defectsAttributes
            // this.items[rowIndex].garmentRemarks = [];
            // for (let i = 0; i < defectsAttributes.length; i++) {
            //     const object = this.defectOptions.find(obj => obj.name == defectsAttributes[i]);
            //     this.items[rowIndex].garmentRemarks.push(object);
            // }
        },
        getColors(colorsAttributes, rowIndex) {
            this.getColorsList(colorsAttributes, rowIndex);
            // this.colorsAttributes = colorsAttributes
            // this.items[rowIndex].colors = [];
            // for (let i = 0; i < colorsAttributes.length; i++) {
            //     const object = this.colorOptions.find(obj => obj.name == colorsAttributes[i].name);
            //     this.items[rowIndex].colors.push({ 'id': object.id, 'name': object.name, "colorCode": object.colorCode, 'createdBy': object.createdBy, 'createdDate': object.createdDate, 'lastModifiedBy': object.lastModifiedBy, 'lastModifiedDate': object.lastModifiedDate });
            // }
        },
        getItemCategory() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/itemCategories', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.categoryOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectItemCategory(name) {
            this.itemName = '';
            this.servicesOption = ''
            this.getItemName(name);
        },
        getItemName(itemCategoryName) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/itemsByCategory/' + itemCategoryName, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.itemNameOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectItemName(id) {
            this.servicesSelected = ''
            this.getServiceName();
        },
        getServiceName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/services', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.servicesOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getServicePriceList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/itemsServicePriceListByCategoryAndItem/filter/' + self.$store.state.storeName + ',' + self.servicesSelected.name + ',' + self.category.name + ',' + self.itemName.name, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.servicesPriceList = response.data;
                        let servicePrice = 0;
                        if (self.defaultConfiguration.taxEnabel) {//tax enable
                            if (self.defaultConfiguration.exclusiveTaxCalculation) {//Exclusive
                                //Normal Rate
                                servicePrice = self.servicesPriceList[0].servicePrice;
                            } else {
                                //set rate
                                servicePrice = parseFloat((self.servicesPriceList[0].servicePrice * 100)/(100+self.defaultConfiguration.taxRate)).toFixed(2);
                            }
                        } else {
                            //Normal Rate
                            servicePrice = self.servicesPriceList[0].servicePrice;
                        }

                        // self.quantity = 1;
                        self.totalQuantity += 1;
                        self.totalPieces += self.servicesPriceList[0].pieceCount;
                        self.totalDefultAmount += servicePrice;
                        self.totalAmount += servicePrice;
                        self.items.push({

                            brands: [],
                            colors: [],
                            defaultServicePrice: servicePrice,
                            garmentRemarks: [],
                            itemId: self.itemName.id,
                            itemName: self.servicesPriceList[0].item,
                            orderServicePrice: servicePrice,
                            quantity: 1,
                            pieceCount: self.servicesPriceList[0].pieceCount,
                            serviceId: self.servicesSelected.id,
                            topUpServices: [],
                            totalAmount: servicePrice,
                            totalTupUpServiceAmount: 0
                        })

                        self.quantityAdd();

                        self.$nextTick(() => {
                            self.trAddHeight(self.$refs.row[0].offsetHeight)
                        })
                    } else {
                        self.$swal({
                            title: '',
                            text: "Service Price Not Added.",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        convertImage(itemPic) {
            return 'data:image/*;base64,' + itemPic
        },
        saveOrder() {
            let self = this;
            if (self.items.length > 0) {

                self.saveOrderDisable=true;
                
                let isDiscountFlat = false;
                if (this.isPercentage) {
                    isDiscountFlat = false;
                } else {
                    isDiscountFlat = true;
                }
                if (this.istaxRateEnable) {
                    this.taxRates = {
                        "store": {
                            "id": this.storeId,
                            "storeName": this.$store.state.storeName
                        },
                        "id": this.defaultConfiguration.taxId,
                        "taxName": this.defaultConfiguration.taxName,
                        "taxRate": this.defaultConfiguration.taxRate,
                        "subTaxName1": this.defaultConfiguration.subTaxName1,
                        "subTaxRate1": this.defaultConfiguration.subTaxRate1,
                        "subTaxName2": this.defaultConfiguration.subTaxName2,
                        "subTaxRate2": this.defaultConfiguration.subTaxRate2
                    }
                } else {
                    this.taxRates = null;
                }
                var axios = require('axios');
                var data = JSON.stringify({
                    "customerId": this.customerId,
                    "discountAmount": this.discountAmountPercentage,
                    "discountFlat": isDiscountFlat,
                    "dueDate": moment.utc(this.dueDate),
                    "garmentInspectorEmpId": this.garmentInspectorBy.empId,
                    "lastUpdatedByEmpId": this.employeeId,
                    "orderCreatedByEmpId": this.employeeId,
                    "orderItems": this.items,
                    "orderRemark": this.orderRemark,
                    "storeId": this.storeId,
                    "totalAmountLessFromDefaultAmount": this.amountLessFromDefaultAmount,
                    "totalDefultAmount": this.totalDefultAmount,
                    "totalDueAmount": this.totalDueAmount,
                    "totalGrossAmount": this.totalGrossAmount,
                    "totalItems": this.totalQuantity,
                    "totalNetAmount": this.totalNetAmount,
                    "totalPices": this.totalPieces,
                    "taxRates": this.taxRates,
                    "totalTaxAmount": this.totalTaxAmount,
                    "totalAdjustmentAmount": 0,
                    "totalPayedAmount": 0,
                    "urgent1": this.urgent1,
                    "urgent2": this.urgent2
                })
                // alert(data)
                axios(api.getApi('Post', '/orders/createOrder', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Order Created Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })

                        self.$router.push({ name: 'orderDetail', params: { orderId: response.data.id } })
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.saveOrderDisable=false;
                    });
            } else {
                this.$swal({
                    title: '',
                    text: 'Please Select Items!',
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-secondary',
                    },
                    buttonsStyling: false,
                })
            }




        },
        addAttribute(index) {
            this.rowIndex = index;

            this.topupAttributes = [];
            for (let i = 0; i < this.items[index].topUpServices.length; i++) {
                this.topupAttributes.push(this.items[index].topUpServices[i]);
            }

            this.brandsAttributes = [];
            for (let i = 0; i < this.items[index].brands.length; i++) {
                this.brandsAttributes.push(this.items[index].brands[i].name);
            }

            this.defectsAttributes = [];
            for (let i = 0; i < this.items[index].garmentRemarks.length; i++) {
                this.defectsAttributes.push(this.items[index].garmentRemarks[i].name);
            }

            this.colorsAttributes = [];
            for (let i = 0; i < this.items[index].colors.length; i++) {
                this.colorsAttributes.push(this.items[index].colors[i]);
            }



        },

        getTopupServicesList(topupAttributes, rowIndex) {

            let self = this;
            self.topUpServieOption = [];
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/topUpServices', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.topUpServieOption = response.data;

                    self.topupAttributes = topupAttributes;
                    self.items[rowIndex].topUpServices = [];
                    self.items[rowIndex].totalTupUpServiceAmount = 0
                    for (let i = 0; i < topupAttributes.length; i++) {
                        const object = self.topUpServieOption.find(obj => obj.name == topupAttributes[i].name);
                        self.items[rowIndex].topUpServices.push({ "id": object.id, "name": object.name, "topUpServicePrice": object.topUpServicePrice, 'createdBy': object.createdBy, 'createdDate': object.createdDate, 'lastModifiedBy': object.lastModifiedBy, 'lastModifiedDate': object.lastModifiedDate });
                        self.items[rowIndex].totalTupUpServiceAmount += object.topUpServicePrice;
                    }
                    self.quantityAdd();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getBrandsList(brandsAttributes, rowIndex) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/brands', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    // alert(JSON.stringify(response.data))
                    self.brandsOptions = response.data;

                    self.brandsAttributes = brandsAttributes
                    self.items[rowIndex].brands = [];
                    for (let i = 0; i < brandsAttributes.length; i++) {
                        const object = self.brandsOptions.find(obj => obj.name == brandsAttributes[i]);
                        self.items[rowIndex].brands.push(object);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getGarmentRemarks(defectsAttributes, rowIndex) {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/garmentRemarks', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.defectOptions = response.data;

                    self.defectsAttributes = defectsAttributes
                    self.items[rowIndex].garmentRemarks = [];
                    for (let i = 0; i < defectsAttributes.length; i++) {
                        const object = self.defectOptions.find(obj => obj.name == defectsAttributes[i]);
                        self.items[rowIndex].garmentRemarks.push(object);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getColorsList(colorsAttributes, rowIndex) {
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/colors', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.colorOptions = response.data;

                    self.colorsAttributes = colorsAttributes
                    self.items[rowIndex].colors = [];
                    for (let i = 0; i < colorsAttributes.length; i++) {
                        const object = self.colorOptions.find(obj => obj.name == colorsAttributes[i].name);
                        self.items[rowIndex].colors.push({ 'id': object.id, 'name': object.name, "colorCode": object.colorCode, 'createdBy': object.createdBy, 'createdDate': object.createdDate, 'lastModifiedBy': object.lastModifiedBy, 'lastModifiedDate': object.lastModifiedDate });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onSelected(option) {
            //this.selected = option.item
            this.searchBoxValue = option.mobileNo;
            this.customerId = option.id;
            if (util.digits_count(option.id) == 3) {
                this.customerIdName = "0" + option.id;
            } else if (util.digits_count(option.id) == 2) {
                this.customerIdName = "00" + option.id;
            }
            else if (util.digits_count(option.id) == 1) {
                this.customerIdName = "000" + option.id;
            }

            this.customerName = option.name;
            this.customerMobileNo = option.mobileNo;
            this.customerAddress = option.address;

            this.customerDiscountRatePercentage = option.defaultDiscountPercentage;
            this.discountAmountPercentage = this.customerDiscountRatePercentage;

            this.isHomeDeliveryAllowed = option.enabelHomeDelevery;
            this.enableHomeDelivery = this.isHomeDeliveryAllowed;

            this.discountCalculation();
        },

        autoSelected(value) {
            //this.selected = value.item
            this.searchBoxValue = value.mobileNo;
            this.customerId = value.id;
            if (util.digits_count(value.id) == 3) {
                this.customerIdName = "0" + value.id;
            } else if (util.digits_count(value.id) == 2) {
                this.customerIdName = "00" + value.id;
            }
            else if (util.digits_count(value.id) == 1) {
                this.customerIdName = "000" + value.id;
            }

            this.customerName = value.name;
            this.customerMobileNo = value.mobileNo;
            this.customerAddress = value.address;

            this.customerDiscountRatePercentage = value.defaultDiscountPercentage;
            this.discountAmountPercentage = this.customerDiscountRatePercentage;

            this.isHomeDeliveryAllowed = value.enabelHomeDelevery;
            this.enableHomeDelivery = this.isHomeDeliveryAllowed;

            this.discountCalculation();
        },

        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            this.getCustomerList(text);
        },
        getCustomerList(text) {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/customersDetails/filter/' + this.searchCritria.filterType + ',' + text, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersList = response.data;

                        if (self.customersList.length == 1) {
                            self.autoSelected(self.customersList[0])
                        }
                    } else {
                        self.customersList = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        quantityAdd() {
            this.totalQuantity = 0;
            this.totalPieces = 0;
            for (let i = 0; i < this.items.length; i++) {
                this.totalQuantity += this.items[i].quantity;
                this.totalPieces += (this.items[i].quantity * this.items[i].pieceCount);
            }


            this.totalAmount = 0;
            this.totalDefultAmount = 0;
            for (let i = 0; i < this.items.length; i++) {
                this.items[i].totalAmount = this.items[i].quantity * this.items[i].orderServicePrice;

                this.totalDefultAmount += (this.items[i].quantity * this.items[i].defaultServicePrice) + this.items[i].totalTupUpServiceAmount;

                this.totalAmount = (this.totalAmount + this.items[i].totalAmount) + this.items[i].totalTupUpServiceAmount;


            }

            this.amountLessFromDefaultAmount = 0;
            this.amountLessFromDefaultAmount = this.totalDefultAmount - this.totalAmount;

            this.discountCalculation();
            this.taxCalculation();

        },
        onInputChangeAmount() {
            this.quantityAdd();
        },
        setDueDate() {
            this.dueDays = this.defaultConfiguration.orderConfiguration.deleveryDateOffset;

            const date = new Date();
            const dueDateUnicode = date.setDate(date.getDate() + this.dueDays);
            this.dueDate = moment.utc(dueDateUnicode).local().format('YYYY-MM-DD') + " " + this.defaultConfiguration.orderConfiguration.deliveryTime;
        },
        configration() {
            this.storeId = this.$store.state.storeId;
            this.employeeId = this.$store.state.empId;

            const object = this.garmentInspectorByOptions.find(obj => obj.empId == this.employeeId);
            this.garmentInspectorBy = { 'empId': object.empId, 'name': object.name };


            this.discountAllow = this.defaultConfiguration.orderConfiguration.discountAllow;
            this.isPercentage = this.defaultConfiguration.orderConfiguration.discountTypeIsFlat;

            if (this.defaultConfiguration.orderConfiguration.urgentName1 == "" && this.defaultConfiguration.orderConfiguration.urgentRate1 == 0 && this.defaultConfiguration.orderConfiguration.urgentDatOffSet1 == 0) {
                this.isUrgentOrder1 = false;
                this.urgent1 = false;
                this.urgentRate1 = 0;
                this.urgentName1 = '';
                this.urgentDatOffSet1 = 0;
            } else {
                this.isUrgentOrder1 = true;
                this.urgent1 = false;
                this.urgentRate1 = this.defaultConfiguration.orderConfiguration.urgentRate1;
                this.urgentName1 = this.defaultConfiguration.orderConfiguration.urgentName1;
                this.urgentDatOffSet1 = this.defaultConfiguration.orderConfiguration.urgentDayOffSet1;
            }

            if (this.defaultConfiguration.orderConfiguration.urgentName2 == "" && this.defaultConfiguration.orderConfiguration.urgentRate2 == 0 && this.defaultConfiguration.orderConfiguration.urgentDatOffSet2 == 0) {
                this.isUrgentOrder2 = false;
                this.urgent2 = false;
                this.urgentRate2 = 0;
                this.urgentName2 = '';
                this.urgentDatOffSet2 = 0;
            } else {
                this.isUrgentOrder2 = true;
                this.urgent2 = false;
                this.urgentRate2 = this.defaultConfiguration.orderConfiguration.urgentRate2;
                this.urgentName2 = this.defaultConfiguration.orderConfiguration.urgentName2;
                this.urgentDatOffSet2 = this.defaultConfiguration.orderConfiguration.urgentDayOffSet2;
            }

        },
        discountCalculation() {
            if (this.discountAllow) {
                if (this.customerDiscountRatePercentage == 0) {
                    // this.isPercentage = this.defaultConfiguration.orderConfiguration.discountTypeIsFlat;
                    if (this.defaultConfiguration.orderConfiguration.discountTypeIsFlat) {
                        this.isPercentage = false;
                    } else {
                        this.isPercentage = true;
                    }
                    var value = '';
                    if (this.isPercentage) {
                        if (this.discountAmountPercentage > this.maxDiscountRestricationPersentage) {
                            this.discountAmountPercentage = value;

                            this.$swal({
                                title: '',
                                text: "Percentage Greater Than Max Discount Configuration Percentage ( " + this.maxDiscountRestricationPersentage + "% )!",
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            }).then(result => {
                                if (result.value) {
                                    this.discountAmountPercentage = 0;
                                    this.totalGrossAmount = this.totalAmount - ((this.totalAmount * this.discountAmountPercentage) / 100);
                                    this.taxCalculation();
                                }
                            })
                        } else {
                            //this.discountAmountPercentage=this.customerDiscountRatePercentage;
                            this.totalGrossAmount = this.totalAmount - ((this.totalAmount * this.discountAmountPercentage) / 100);
                            this.taxCalculation()
                        }
                    } else {
                        if (this.discountAmountPercentage > this.maxDiscountRestricationFlat) {
                            this.discountAmountPercentage = value;

                            this.$swal({
                                title: '',
                                text: "Flat Amount Greater Than Max Discount Configuration Amount ( " + this.maxDiscountRestricationFlat + " )!",
                                icon: 'error',
                                customClass: {
                                    confirmButton: 'btn btn-primary',
                                },
                                buttonsStyling: false,
                            }).then(result => {
                                if (result.value) {
                                    this.discountAmountPercentage = 0;
                                    this.totalGrossAmount = this.totalAmount - this.discountAmountPercentage;
                                    this.taxCalculation()
                                }
                            })
                        } else {
                            this.totalGrossAmount = this.totalAmount - this.discountAmountPercentage;
                            this.taxCalculation()
                        }
                        // this.totalGrossAmount = this.totalAmount - this.discountAmountPercentage;
                    }

                } else {
                    this.isPercentage = true;
                    var value = '';
                    if (this.discountAmountPercentage > this.maxDiscountRestricationPersentage) {
                        this.discountAmountPercentage = value;

                        this.$swal({
                            title: '',
                            text: "Percentage Greater Than Max Discount Configuration Percentage ( " + this.maxDiscountRestricationPersentage + "% )!",
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        }).then(result => {
                            if (result.value) {
                                this.discountAmountPercentage = 0;
                                this.totalGrossAmount = this.totalAmount - ((this.totalAmount * this.discountAmountPercentage) / 100);
                                this.taxCalculation();
                            }
                        })
                    } else {
                        //this.discountAmountPercentage=this.customerDiscountRatePercentage;
                        this.totalGrossAmount = this.totalAmount - ((this.totalAmount * this.discountAmountPercentage) / 100);
                        this.taxCalculation()
                    }
                }
            } else {
                this.totalGrossAmount = this.totalAmount;
                this.taxCalculation()
            }

            // this.taxCalculation()
        },
        taxCalculation() {
            this.istaxRateEnable = this.defaultConfiguration.taxEnabel;
            if (this.istaxRateEnable) {
                this.taxNameAndRate = this.defaultConfiguration.taxName;
                this.totalTaxRate = this.defaultConfiguration.taxRate;
                this.totalTaxAmount = (this.totalGrossAmount * this.defaultConfiguration.taxRate) / 100;

                this.subTaxNameAndRate1 = this.defaultConfiguration.subTaxName1
                this.totalSubTaxAmount1 = this.defaultConfiguration.subTaxRate1;

                this.subTaxNameAndRate2 = this.defaultConfiguration.subTaxName2
                this.totalSubTaxAmount2 = this.defaultConfiguration.subTaxRate2;

                this.totalNetAmount = 0;
                this.totalNetAmount = this.totalGrossAmount + this.totalTaxAmount;
                if (this.defaultConfiguration.orderConfiguration.discountRounded) {
                    this.totalNetAmount = Math.round(this.totalNetAmount);
                    this.totalDueAmount = this.totalNetAmount;
                } else {
                    this.totalDueAmount = this.totalNetAmount;
                }

            } else {
                // this.totalNetAmount = 0;
                this.totalNetAmount = this.totalGrossAmount;
                if (this.defaultConfiguration.orderConfiguration.discountRounded) {
                    this.totalNetAmount = Math.round(this.totalNetAmount);
                    this.totalDueAmount = this.totalNetAmount;
                } else {
                    this.totalDueAmount = this.totalNetAmount;
                }

            }

        },
        getGarmentInspectedBy() {
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/getStoreEmployeesDetails/' + this.$store.state.storeId + ',' + 0, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.garmentInspectorByOptions = response.data;
                    self.configration();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        setUrgent1() {
            this.urgent2 = false;
            if (this.urgent1) {
                this.totalNetAmount = this.totalNetAmount + ((this.totalNetAmount * this.defaultConfiguration.orderConfiguration.urgentRate1) / 100);
                this.totalDueAmount = this.totalNetAmount;
                const date = new Date();
                const dueDateUnicode = date.setDate(date.getDate() + this.defaultConfiguration.orderConfiguration.urgentDayOffset1);
                // this.dueDate = dueDateUnicode;
                this.dueDate = moment.utc(dueDateUnicode).local().format('YYYY-MM-DD') + " " + this.defaultConfiguration.orderConfiguration.deliveryTime;
            } else {
                this.totalNetAmount = (this.totalNetAmount / (100 + this.defaultConfiguration.orderConfiguration.urgentRate1)) * 100;
                this.totalDueAmount = this.totalNetAmount;
                this.setDueDate();
            }

        },

        setUrgent2() {
            this.urgent1 = false;
            if (this.urgent2) {
                this.totalNetAmount = this.totalNetAmount + ((this.totalNetAmount * this.defaultConfiguration.orderConfiguration.urgentRate2) / 100);
                this.totalDueAmount = this.totalNetAmount;
                const date = new Date();
                const dueDateUnicode = date.setDate(date.getDate() + this.defaultConfiguration.orderConfiguration.urgentDayOffset2);
                // this.dueDate = dueDateUnicode;
                this.dueDate = moment.utc(dueDateUnicode).local().format('YYYY-MM-DD') + " " + this.defaultConfiguration.orderConfiguration.deliveryTime;
            } else {
                this.totalNetAmount = (this.totalNetAmount / (100 + this.defaultConfiguration.orderConfiguration.urgentRate2)) * 100;
                this.totalDueAmount = this.totalNetAmount;
                this.setDueDate();
            }

        },

    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}

.odd {
    background-color: #e9ecef;
    border-radius: 20px;
}

.even {
    background-color: #caddf1;
    border-radius: 20px;
}

.tag-margin-bottom {
    margin-bottom: .0rem;
    font-size: 0.9rem;
}

.tag-margin-top {
    margin-top: .1rem;
}

.input-box {
    font-size: 0.8rem;
    height: 1.4rem;
    width: 80px;
}

.font-color {
    color: #000000;
}

.service-font-size {
    font-size: 0.8rem;
    float: right;
}

.attribute-font-size {
    font-size: 0.8rem;
}

.edit-button {
    margin-right: 1rem;
    margin-left: 1rem;

}


.spin-button {
    width: 100px;
    font-size: 0.8rem;
    height: 1.3rem;
}

.inline {
    padding: 1px;
    display: inline;
}

.padding_attribute {
    padding: 0.5rem;
}
</style>