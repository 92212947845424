import { render, staticRenderFns } from "./NewOrder.vue?vue&type=template&id=8ca4fb3c&scoped=true&"
import script from "./NewOrder.vue?vue&type=script&lang=js&"
export * from "./NewOrder.vue?vue&type=script&lang=js&"
import style0 from "./NewOrder.vue?vue&type=style&index=0&id=8ca4fb3c&prod&lang=scss&"
import style1 from "./NewOrder.vue?vue&type=style&index=1&id=8ca4fb3c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ca4fb3c",
  null
  
)

export default component.exports