<template>
  <b-sidebar id="sidebar-right" width="80%" right shadow bg-variant="white" backdrop>
    <template #default="{ hide }">
      <b-card>
        <b-form>
          <b-tabs>
            <b-tab active title="Top Up Service">
              <b-card title="Top Up Services">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Topup Service Name">
                      <b-form-input v-model="topupName" placeholder="Please Enter Topup Service Name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Topup Service Price">
                      <b-form-input v-model="topupPrice" placeholder="Please Enter Topup Service Price"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-button class="mt-2" variant="primary" @click="addTopUpServices">Add</b-button>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="2" v-for="item in  topUpServieOption" :key="item">
                    <!-- <b-form-group> -->
                    <b-row class="mr-2 mt-2">
                      <input type="checkbox" :value="item" v-model="topupAttributes"> <span class="margin-checkbox"> {{
    item.name
}} </span>
                      <b-form-input v-model="item.topUpServicePrice"></b-form-input>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <b-button variant="primary" class="mt-2 mr-2" @click="hide"
                      v-on:click="getTopUpAttributes(topupAttributes,rowIndex)">
                      Proceed</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab title="Brands">
              <b-card title="Brands">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Brand Name">
                      <b-form-input v-model="brandAdd" placeholder="Please Enter Brand Name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-button class="mt-2" variant="primary" @click="addBrands">Add</b-button>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox-group v-model="brandsAttributes" :options="brandsOptions" text-field="name"
                        value-field="name" class="demo-inline-spacing p-1" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <b-button variant="primary" class="mt-2 mr-2" @click="hide"
                      v-on:click="getBrands(brandsAttributes,rowIndex)">
                      Proceed</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab title="Defects / Description">
              <b-card title="Defects / Description">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Defects / Description Name">
                      <b-form-input v-model="defectAdd" placeholder="Please Enter Defects / Description"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-button class="mt-2" variant="primary" @click="addDefect">Add</b-button>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col>
                    <b-form-group>
                      <b-form-checkbox-group v-model="defectsAttributes" :options="defectOptions" text-field="name"
                        value-field="name" class="demo-inline-spacing p-1" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <b-button variant="primary" class="mt-2 mr-2" @click="hide"
                      v-on:click="getDefects(defectsAttributes,rowIndex)">
                      Proceed</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab title="Colors">
              <b-card title="Colors">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Color Name">
                      <b-form-input v-model="colorName" placeholder="Please Enter Color Name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Color Code">
                      <b-form-input type="color" v-model="colorCode" placeholder="Please Enter Color Code">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-button class="mt-2" variant="primary" @click="addColor">Add</b-button>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="2" v-for="item in  colorOptions" :key="item">
                    <!-- <b-form-group> -->
                    <b-row class="mr-2 mt-2">
                      <input type="checkbox" :value="item" v-model="colorsAttributes"> <span
                        class="margin-checkbox"> {{ item.name }} </span>
                      <b-img class="margin-checkbox" v-bind="mainProps" :blank-color="item.colorCode" rounded
                        alt="code" />
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <b-button variant="primary" class="mt-2 mr-2" @click="hide"
                      v-on:click="getColors(colorsAttributes,rowIndex)">
                      Proceed</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-form>
      </b-card>
    </template>
  </b-sidebar>
</template>
  
<script>
import {
  BCard, BRow, BCol, BSidebar, BFormInput, BButton, BForm, BCardText, BTabs, BTab, BInputGroupPrepend, BFormCheckbox, BFormGroup, BImg, BFormCheckboxGroup
}
  from 'bootstrap-vue'
import api from '@/store/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { integer } from 'vee-validate/dist/rules'

export default {
  components: {
    BTabs, BCardText, BCard, BSidebar, BRow, BCol, BFormInput, BButton, BForm, BTab, BInputGroupPrepend, BFormCheckbox, BFormGroup, BImg, BFormCheckboxGroup
  },
  data() {
    return {
      mainProps: {
        blank: true,
        width: 20,
        height: 20,
        class: 'm2',
      },
      topUpServieOption: [],
      brandsOptions: [],
      defectOptions: [],
      colorOptions: [],
      colorCode: '#ff0080',
      colorName: '',

      topUpServiceSelect: [],
      brandOptionsSelect: [],
      brandAdd: '',
      defectOptionsSelect: [],
      defectAdd: '',
      colorOptionsSelect: [],
      topupName: '',
      topupPrice: '',
      topupAttributes: [],
      brandsAttributes: [],
      defectsAttributes: [],
      colorsAttributes: []




    }
  },
  props: {
    getTopUpAttributes: Function,
    getBrands: Function,
    getDefects: Function,
    getColors: Function,
    rowIndex: integer,
    brandsAttributes: Object,
    topupAttributes: Object,
    defectsAttributes: Object,
    colorsAttributes: Object,

  },
  methods: {
    getTopupServices() {

      let self = this;
      self.topupName = '',
        self.topupPrice = ''

      self.topUpServieOption = [];
      var axios = require('axios');
      var data = '';

      axios(api.getApi('get', '/masters/topUpServices', data))
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          self.topUpServieOption = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addTopUpServices() {
      let self = this;
      if (!(self.topupName == '') && !(self.topupPrice == '')) {
        var axios = require('axios');
        var data = JSON.stringify({ "name": self.topupName, "topUpServicePrice": self.topupPrice });
        axios(api.getApi('Post', '/masters/topUpServices', data))
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Topup Service Added Successfully!`,
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
            self.getTopupServices();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$swal({
          title: '',
          text: "Please Enter Top Up Service Name And Price.",
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }


    },
    getBrandsList() {

      this.brandAdd = '';
      let self = this;

      var axios = require('axios');
      var data = '';
      axios(api.getApi('get', '/masters/brands', data))
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          self.brandsOptions = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addBrands() {
      let self = this;
      if (!(self.brandAdd == '')) {
        var axios = require('axios');
        var data = JSON.stringify({ "name": self.brandAdd });
        axios(api.getApi('Post', '/masters/brands', data))
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Brand Added Successfully!`,
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
            self.getBrandsList();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$swal({
          title: '',
          text: "Please Enter Brand Name.",
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }


    },

    getGarmentRemarks() {
      this.id = 0;
      this.defectAdd = '';
      let self = this;

      var axios = require('axios');
      var data = '';
      axios(api.getApi('get', '/masters/garmentRemarks', data))
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          self.defectOptions = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addDefect() {
      let self = this;
      if (!(self.defectAdd == '')) {
        var axios = require('axios');
        var data = JSON.stringify({ "name": self.defectAdd });
        axios(api.getApi('Post', '/masters/garmentRemarks', data))
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Remarks / Defects Added Successfully!`,
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
            self.getGarmentRemarks();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$swal({
          title: '',
          text: "Please Enter Remarks / Defects Name.",
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }


    },

    getColorsList() {
      this.colorName = '';
      this.colorCode = '';
      let self = this;

      var axios = require('axios');
      var data = '';

      axios(api.getApi('get', '/masters/colors', data))
        .then(function (response) {
          //console.log(JSON.stringify(response.data));
          self.colorOptions = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    addColor() {
      let self = this;
      if (!(self.colorName == '')) {
        var axios = require('axios');
        var data = JSON.stringify({ "name": self.colorName, "colorCode": self.colorCode });
        axios(api.getApi('Post', '/masters/colors', data))
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
            self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Color Added Successfully!`,
                icon: 'SaveIcon',
                variant: 'success',
              },
            })
            self.getColorsList();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        this.$swal({
          title: '',
          text: "Please Enter Color Name.",
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }


    },

  },
  created() {
    this.getTopupServices();
    this.getBrandsList();
    this.getGarmentRemarks();
    this.getColorsList();
  }
}
</script>
<style>
.margin-checkbox {
  margin-left: 10px;
}
</style>